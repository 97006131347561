#contact {
  margin: 0 10% 0 25%;
}
.profile-container {
  padding: 4% 7% 4% 7%;
}
.profile-card {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: #fff solid 4px;
  transition: 0.2s;
  box-shadow: rgba(77, 82, 88, 0.1) 0px 2px 2px 2px;
}
.profile-card:hover {
  transform: scale(1.06);
  box-shadow: rgba(41, 41, 43, 0.2) 0px 7px 29px 10px;
  z-index: 10;
  border: none;
}

.profile-icon {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.only-start {
  background: linear-gradient(
    to bottom,
    rgb(61, 58, 71),
    rgb(37, 35, 39) 100px,
    white 1rem,
    white 100%
  );
}

.profile-name {
  font-size: 21px;
  font-weight: bold;
  margin: 10px 0 1px 0;
}

.profile-position {
  font-size: 15px;
  color: rgb(53, 52, 52);
}
.profile-contact {
  font-size: 12px;
}

@media screen and (max-width: 991px) {
  .profile-name {
    font-size: 18px;
  }

  .profile-container {
    padding: 4% 15% 4% 15%;
  }
}

@media screen and (max-width: 768px) {
  .profile-card {
    padding: 25px 15px;
  }

  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(34, 33, 33),
      rgb(34, 33, 33) 80px,
      white 1rem,
      white 100%
    );
  }

  .profile-card {
    padding: 20px 0px;
  }

  .profile-name {
    font-size: 16px;
  }

  .profile-container {
    padding: 4% 25% 4% 25%;
  }
}

@media (min-device-width: 20px) and (max-device-width: 500px) {
  .profile-card {
    width: 50vw;
    padding: 5px;
    background-color: "blue";
  }
  .profile-icon {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 50%;
  }
  .profile-position {
    text-align: center;
    width: 70%;
  }

  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(34, 33, 33),
      rgb(34, 33, 33) 60px,
      white 1rem,
      white 100%
    );
  }
}

/* --------------------- Social Icons --------------------- */
.social-icons {
  display: flex;
  margin-top: 15%;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 1rem 0.6rem;
  border-radius: 50%;
  font-size: 2.5rem;
  text-decoration: none;
  transition: all 0.15s ease;
}

.social-icon:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.social-icon--linkedin,
.social-icon--envelope,
.social-icon--phone {
  background: #1a1a1a;
}
.social-icon i {
  position: relative;
  font-size: 16px;
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .profile-icon {
    height: 150px;
    width: 150px;
  }
  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(61, 58, 71),
      rgb(37, 35, 39) 140px,
      white 1rem,
      white 100%
    );
  }
  .profile-name {
    font-size: 28px;
    font-weight: bold;
    margin: 10px 0 1px 0;
  }

  .profile-position {
    font-size: 20px;
    color: rgb(53, 52, 52);
  }
  .profile-contact {
    font-size: 18px;
  }
}
