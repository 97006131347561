/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 800px) {
  /* ------------ Dashboard ------------ */
  .dashboard-row {
    transform: translateX(5%);
    margin: auto;
    width: 85%;
  }
  .timeline-row,
  .contactUs-row,
  .dashboard-timeline-row,
  .progressbar-row {
    display: none;
  }
  iframe {
    width: 100%;
  }

  .currentphase-text h4 {
    font-size: 30px;
    text-align: center;
  }
  .currentphase-text p {
    font-size: 18px;
    text-align: center;
  }
  .submissionForm-row {
    margin-bottom: 10%;
  }
  .faq-row {
    margin: auto;
    transform: translateX(-10%);
    width: 115%;
  }
  .faq-heading {
    text-align: center;
  }
  .faq-card-body {
    padding: 5%;
  }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 800px) and (max-device-width: 1200px) {
  /* timeline */
  .timeline-title {
    font-size: 12px;
  }
  .timeline-info {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0 0 0.2em 0;
  }

  iframe {
    width: 100%;
  }
  /* right */
  .contactUs-text {
    font-size: 17px;
    line-height: 0.6;
  }

  .faq {
    margin-bottom: 30px;
  }
  .faq .faq-card .faq-card-header .faq-title {
    line-height: 16px;
    margin-top: 10px;
    font-size: 12px;
  }
  .faq .faq-card .faq-card-header .faq-title .badge {
    width: 15px;
    height: 15px;
    font-size: 5px;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1800px) {
  iframe {
    width: 100%;
  }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
  iframe {
    width: 100%;
  }
  .dashboard-timeline-title {
    font-size: 23px;
  }
  .dashboard-timeline-info {
    font-size: 20px;
  }
  .dashboard-timeline {
    font-size: 23px;
  }
  .currentphase-text p {
    font-size: 23px;
    text-align: center;
  }
  .dashboard-phase-btn {
    font-size: 20px;
    padding: 4% 3% 4% 3%;
  }

  .contactUs-text {
    font-size: 20px;
  }
  .contactUs-row {
    height: 8%;
  }
  .faq .faq-card .faq-card-header .faq-title {
    font-size: 21px;
  }
  .faq .faq-card .faq-card-body p {
    color: #3d4351;
    font-size: 18px;
  }
}
