.junior {
  padding: 4%;
  background-color: #1a1a1a;
  position: relative;
}
.Jcard {
  margin-top: 6%;
  margin-bottom: 1%;
  position: relative;
  background-color: #1a1a1a;
  overflow: hidden;
}
.Jheading {
  z-index: 5;
  font-family: "Ralesway", sans-serif;
  position: absolute;
  bottom: 0;
  right: 95px;
  font-weight: 800;
  color: #faf4f4;
  transform: translateY(-20%);
}
.coming-soon {
  font-family: "Ralesway", sans-serif;
  position: absolute;
  bottom: 0;
  right: 95px;
  font-weight: 700;
  color: #c5c5c5;
}

.Jarrow {
  opacity: 1;
  color: #fff;
  transform: scale(0.2);
  transition: all 0.2s ease;
  z-index: 100;
  text-align: center;
  display: block;
  height: 90%;
  width: 90%;
  margin: auto;
  margin-top: -50%;
  font-size: 100px;
  font-weight: 900;
  cursor: pointer;
}

.Jbutton {
  position: absolute;
  right: 25px;
  bottom: 18px;
  background-color: #a87ed8;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  mix-blend-mode: hard-light;
  visibility: hidden;
}
.Jarrow {
  padding-top: 7%;
}

.junior-hover-section:hover .Jarrow,
.Jbutton:hover {
  opacity: 1;
}
.junior-hover-section:hover button,
.Jbutton:hover {
  transform: scale(8);
}
.Jcard button:focus {
  border: none;
  outline: none;
}

.mainsite-footer-animation {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.mainsite-footer-animation svg {
  position: relative;
  display: block;
  width: calc(153% + 1.3px);
  height: 150px;
}

.mainsite-footer-animation .shape-fill {
  fill: #ffffff;
}

/** For tablet devices **/
@media (min-width: 601px) and (max-width: 1023px) {
  .mainsite-footer-animation svg {
    width: calc(160% + 1.3px);
    height: 150px;
  }
}

/** For mobile devices **/
@media (max-width: 700px) {
  .mainsite-footer-animation svg {
    width: calc(174% + 1.3px);
    height: 90px;
  }
}

@media (max-width: 500px) {
  .mainsite-footer-animation svg {
    width: calc(164% + 1.3px);
    height: 50px;
  }
  .junior-heading {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
  }
  .Jheading {
    position: static;
    margin-bottom: -8px;
  }
  .coming-soon {
    position: static;
  }
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .mainsite-footer-animation svg {
    width: calc(127% + 10px);
    height: 170px;
  }
  .Jheading {
    font-size: 75px;
    transform: translateY(-30%);
  }
  .coming-soon {
    font-size: 30px;
    right: 95px;
  }
}
