/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 500px) {
  html,
  body {
    overflow-x: hidden;
  }
  .heading,
  .heading span {
    font-size: 32px;
  }

  .content {
    font-size: 15px;
  }
  .menu-wrap .menu > div {
    width: 220vw;
    height: 220vw;
  }
  .menu-wrap .menu > div > div > ul > li {
    font-size: 1.5rem;
    padding: 2%;
  }

  /*------------ Ibeto main page ------------*/
  /* landing page */
  .animation-container {
    width: 105vw;
    height: 63vh;
    position: absolute;
    background-color: #1a1a1a;
  }
  .actual-line-animation {
    background-color: #1a1a1a;
    height: 63vh;
    width: 100vw;
    position: absolute;
  }
  .landingpage-img {
    height: 63vh;
    width: 100vw;
  }
  .bg,
  .buttons {
    margin-top: -190%;
    margin-left: 30%;
    width: 55px;
    height: 55px;
  }
  video {
    display: block;
    width: 100%;
    height: 100%;
  }
  .wave-pattern {
    position: relative;
    top: 5%;
    visibility: hidden;
  }
  .buttons i {
    font-size: 20px;
  }

  /* About */
  .about {
    transform: translateY(26%);
  }

  .about-logo {
    width: 65%;
  }

  /* Event Format */

  .event-format-row {
    margin-top: 80%;
  }
  .phase {
    margin: 5%;
    margin-left: 12%;
    margin-bottom: 5%;
    margin-right: 12%;
    padding: 5%;
  }
  .phase-title {
    text-align: center;
  }
  /* Problem stmt */

  .card-front {
    height: 83%;
    width: 80%;
  }
  .card-title {
    font-size: 21px;
  }
  .card-back {
    padding: 5%;
    height: 83%;
    width: 80%;
  }
  .view-sample-stmt-btn {
    width: 90%;
    display: block;
    margin: 0 auto;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-color: white;
  }
  /* Rewards */
  .rewards-row {
    display: block;
    margin: 0 auto;
    padding: 5%;
  }
  .reward-heading {
    text-align: center;
  }
  .rewards-img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }
  .mainsite-timeline-row {
    padding-top: 15%;
  }

  /* Junior */
  .junior {
    padding: 0;
  }
  .Jcard {
    width: 100vw;
    height: 60vh;
  }
  .Jheading {
    font-size: 32px;
  }

  .Jbutton {
    right: 1px;
    width: 35px;
    height: 35px;
  }

  /* Testimonials */
  .testimonial-row {
    padding: 0%;
  }

  .testimonial-content {
    padding-right: 0%;
    padding-left: 0%;
    font-size: 13px !important;
  }
  .carousel-indicators {
    display: none;
  }
  .carousel-control {
    margin-top: 18%;
  }

  /* Sponsers */

  #Sponsors .client-logo {
    padding: 30px;
  }
  .client-logo {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  /* FAQ */
  .accordion {
    padding-left: 10%;
    padding-right: 10%;
  }
  .accordion button .icon {
    width: 3px;
    height: 3px;
  }
  .faq-heading {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .accordion button .accordion-title {
    padding: 0.4em 0.4em 0.6em 0;
  }
  .accordion-title,
  .collapse {
    font-size: 15px;
  }
  .accordion-title {
    width: 60%;
  }
  .pehia-heading {
    text-align: center;
  }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 501px) and (max-device-width: 800px) {
  /*Landing page wave*/
  .wave-shape-divider svg {
    width: calc(147% + 1.3px);
    height: 180px;
  }
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .phase {
    margin: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
  }
  /* timeline */
  .mainsite-timeline-row {
    padding-top: 15%;
  }
  .mainsite-timeline-title {
    font-size: 16px;
  }
  /* junior section */
  .Jheading {
    left: 45px;
    font-size: 50px;
    right: 70px;
    bottom: 7px;
  }
  .Jbutton {
    right: 15px;
    width: 40px;
    height: 40px;
  }
}

/**--------------------------------------------------- For desktop devices ----------------------------------------------------- **/

@media (min-device-width: 800px) and (max-device-width: 1800px) {
  body {
    overflow-x: hidden;
  }
  /* Event format */
  .event-format {
    margin-left: 5%;
    margin-right: 5%;
  }
  .Jheading {
    font-size: 65px;
  }
  .Jbutton {
    width: 50px;
    height: 50px;
  }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
  body {
    overflow-x: hidden;
  }
  .heading,
  .heading span {
    font-size: 60px;
  }

  .content {
    font-size: 25px;
  }
  .bg,
  .buttons {
    margin-top: -320%;
    margin-left: 3%;
    width: 140px;
    height: 140px;
  }
  .buttons i {
    font-size: 30px;
  }

  /* IBETO MAIN SITE */
  .wave-shape-divider svg {
    width: calc(130% + 1.3px);
    height: 181px;
  }

  .about-logo {
    width: 85%;
  }
  /* event */
  .event-format-img {
    width: 90%;
  }
  /* Problem stmt */
  .prbm-stmt {
    margin-bottom: 8%;
  }
  .card-front {
    height: 110%;
    width: 75%;
  }
  .card-title {
    font-size: 30px;
  }
  .card-back {
    font-size: 23px;
    padding: 5%;
    height: 110%;
    width: 75%;
  }
  .view-sample-stmt-btn {
    font-size: 20px;
  }

  .rewards-img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}
