.carousel-control {
  left: -2px;
}
.carousel-control.right {
  right: -2px;
}
.whyparticipate-maindiv {
  margin-left: 10%;
}
.carousel-inner {
  height: 100%;
}
.whyparticipate-div {
  margin: 1%;
  margin-top: 1%;
  margin-bottom: 5%;
  padding-top: 4%;
  text-align: center;
  border-radius: 6px;
  border-bottom: 1px solid var(--secondary);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px -4px 8px rgba(255, 255, 255, 0.8);
}
.whyparticipate-div:hover {
  transition: all 0.8s;
}
.whyparticipate-img {
  display: block;
  width: 38%;
  margin-left: auto;
  margin-right: auto;
}
.whyparticipate-img-smallscreen {
  width: 60%;
}
.carousel-control-prev,
.carousel-control-next {
  color: #424141;
  font-size: 4em;
  text-decoration: none;
}
.carousel-control-prev:link,
.carousel-control-next:link {
  color: #494848;
  font-size: 4em;
  text-decoration: none;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: var(--secondary);
}

.whyparticipate-smallscreen-div {
  text-align: center;
  padding: 15%;
  width: 75%;
  margin-left: 13%;
  margin-right: 13%;
  padding: auto;
  border-radius: 6px;
  border-bottom: 1px solid var(--secondary);
  height: 280px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px -4px 8px rgba(255, 255, 255, 0.8);
  margin-bottom: 20%;
}

.whyparticipate-img-smallscreen {
  margin-top: 5%;
  margin-bottom: 3%;
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .whyparticipate-bigscreen {
    padding-right: 10%;
  }
  .whyparticipate-div {
    margin: 1%;
    padding-left: 3%;
    margin-left: 5%;
    padding-right: 3%;
    margin-top: 1%;
    margin-bottom: 5%;
    padding-top: 5%;
  }
  .carousel-control-next {
    transform: translateX(90%);
  }

  .whyparticipate-img {
    width: 50%;
  }
  .carousel-control.right {
    margin-left: 20%;
  }
}
