.loader_container {
   /* width: 100%;
  height: 100vh;
  color: #1a1a1a;
  z-index: 9999; */
   transform: translateX(-7%);
   top: calc(10% - 4em);
   left: 7%;
   align-items: center;
   background-color: #1a1a1a;
   display: flex;
   height: 100%;
   justify-content: center;
   margin: 0;
   position: absolute;
   width: 100%;
}

.filter {
   position: absolute;
   visibility: hidden;
}

.dots {
   filter: url(#gooeyness);
   padding: 30px;
}

.dot {
   background: white;
   border-radius: 50%;
   display: inline-block;
   margin-right: 20px;
   width: 32px;
   height: 32px;
}

.dot:first-child {
   animation: FirstDot 4.5s infinite;
}

.dot:nth-child(2) {
   animation: SecondDot 4.5s infinite;
}

.dot:nth-child(3) {
   animation: ThirdDot 4.5s infinite;
}

.dot:nth-child(4) {
   animation: FourthDot 4.5s infinite;
}

.dot:nth-child(5) {
   animation: FifthDot 4.5s infinite;
}

@keyframes FirstDot {
   0% {
      transform: scale(1) translateX(0);
   }
   25% {
      transform: scale(2.5) translateX(0);
   }
   50% {
      transform: scale(1) translateX(0);
   }
   83% {
      transform: scale(1) translateX(240px);
   }
   100% {
      transform: scale(1) translateX(0);
   }
}

@keyframes SecondDot {
   0% {
      transform: translateX(0px);
   }
   27% {
      transform: translateX(-40px);
   }
   50% {
      transform: translateX(0px);
   }
   81% {
      transform: translateX(180px);
   }
   100% {
      transform: translateX(0);
   }
}

@keyframes ThirdDot {
   0% {
      transform: translateX(0px);
   }
   29% {
      transform: translateX(-100px);
   }
   50% {
      transform: translateX(0px);
   }
   79% {
      transform: translateX(120px);
   }
   100% {
      transform: translateX(0);
   }
}

@keyframes FourthDot {
   0% {
      transform: translateX(0px);
   }
   31% {
      transform: translateX(-160px);
   }
   50% {
      transform: translateX(0px);
   }
   77% {
      transform: translateX(60px);
   }
   100% {
      transform: translateX(0);
   }
}

@keyframes FifthDot {
   0% {
      transform: scale(1) translateX(0);
   }
   33% {
      transform: scale(1) translateX(-220px);
   }
   50% {
      transform: scale(1) translateX(0);
   }
   75% {
      transform: scale(2.5) translateX(0);
   }
   100% {
      transform: scale(1) translateX(0);
   }
}

/* .loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 5px solid rgb(10, 10, 10);
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
} */

.loader--hide {
   display: none;
}
