@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:wght@700&display=swap);
:root {
  /* 3c368a */
  --dashboard: #4b44ad;
  /* --main:#3C0C97; */
}

/* Dashboard homepage */
.dashboard {
  background: #fafafa;
}
.white-bk {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  margin: 2%;
  margin-top: 10%;
  padding: 5%;
  border-radius: 25px;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.06);
}
.dashboard-row {
  margin-left: 10px;
}

.dashboard-heading {
  font-family: "Raleway", sans-serif;
  font-size: 25px;
}
.ibeto-heading {
  font-size: 32px;
}
/*--------------------------- LEFT SECTION: dashboard-timeline --------------------------- */
.dashboard-timeline-row {
  width: 75%;
}
.dashboard-timeline {
  line-height: 1.9em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #768390;
  font-size: 13px;
}
.dashboard-timeline-title {
  color: #3d4351;
  margin-top: 0;
  font-size: 15px;
}

/*----- dashboard-timeline ITEM -----*/
.dashboard-timeline-item {
  position: relative;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.dashboard-timeline-item:last-child {
  padding-bottom: 0;
}

/*----- dashboard-timeline INFO -----*/
.dashboard-timeline-info {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0 0 0.5em 0;
  white-space: nowrap;
}

/*----- dashboard-timeline MARKER -----*/
.dashboard-timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}
.dashboard-timeline-marker:before {
  background: #622eb4;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.dashboard-timeline-marker:after {
  content: "";
  width: 3px;
  background: #ccd5db;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}
.dashboard-timeline-item:last-child .dashboard-timeline-marker:after {
  content: none;
}

.dashboard-timeline-item:not(.period):hover .dashboard-timeline-marker:before {
  background: transparent;
  border: 3px solid #622eb4;
}

/*----- dashboard-timeline CONTENT -----*/
.dashboard-timeline-content {
  padding-bottom: 20px;
}
.dashboard-timeline-content p:last-child {
  margin-bottom: 0;
}

/*--------------------------- CENTRAL SECTION: dashboard-timeline --------------------------- */
.central-section {
  -webkit-transform: translateX(-15%);
          transform: translateX(-15%);
}

/* Current phase */
.submissionForm-row,
.currentphase-row {
  padding: 4%;
  width: 120%;
}
h1.ss-form-title {
  display: none;
}
.currentphase-row {
  font-size: 16px;
}
.currentphase-text {
  text-align: center;
}

.currentphase-text p {
  font-size: 17px;
}
.dashboard-phase-btn {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  -webkit-text-decoration: None !important;
          text-decoration: None !important;
  background-image: linear-gradient(90deg, #7819bb, #4b44ad);
  color: #fff;
  border: 0px solid #fff;
  outline: none;
  transition: color 0.5s;
  border-radius: 10px;
  padding: 8% 7% 8% 7%;
  text-align: center;
  width: 90%;
  cursor: pointer;
  margin-top: 10%;

  transition-timing-function: ease-in-out;
  transition-duration: 1s;
}

.dashboard-phase-btn:hover {
  color: #4b44ad;
  background: white;
  border: 1px solid #4b44ad;
}
iframe {
  width: 450px;
}

/* dashboard-logo */
.dashboard-logo {
  width: 10vw;
  height: 10vh;
}

/*--------------------------- Right SECTION:  --------------------------- */
/* Contact us */
.contactUs-row {
  width: 75%;
  height: 7%;
  margin-left: 15%;
  padding-left: 10%;
  padding-right: 10%;
  background-image: linear-gradient(to right, #7819bb, #4b44ad);
}
.contactUs-row:hover {
  background-image: linear-gradient(to right, #5951cc, #712fa0);
}
.sendmailbtn,
.sendmailbtn :hover {
  text-decoration: none;
  color: inherit !important;
}

.contactUs-text {
  font-size: 18px;
  line-height: 0.4;
  font-weight: 200px;
  color: rgb(221, 218, 224);
}

/* FAQ */
.faq-row {
  width: 75%;
  margin-left: 15%;
}

.faq .faq-card {
  border: none;
  background: none;
  border-bottom: 1px dashed #cee1f8;
}

.faq .faq-card .faq-card-header {
  padding: 0px;
  border: none;
  background: none;
  transition: all 0.3s ease 0s;
}

.faq .faq-card .faq-card-header:hover {
  background: rgba(74, 30, 233, 0.1);
  padding-left: 10px;
}
.faq .faq-card .faq-card-header .faq-title {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 400;
  font-size: 15px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faq .faq-card .faq-card-header .faq-title .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  text-align: center;
  background: #622eb4;
  color: #fff;
  font-size: 10px;
  padding-top: 5px;
  margin-right: 13px;
}

.faq .faq-card .faq-card-body p {
  color: #3d4351;
  font-size: 14px;
  margin-bottom: 30px;
}

/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 800px) {
  /* ------------ Dashboard ------------ */
  .dashboard-row {
    -webkit-transform: translateX(5%);
            transform: translateX(5%);
    margin: auto;
    width: 85%;
  }
  .timeline-row,
  .contactUs-row,
  .dashboard-timeline-row,
  .progressbar-row {
    display: none;
  }
  iframe {
    width: 100%;
  }

  .currentphase-text h4 {
    font-size: 30px;
    text-align: center;
  }
  .currentphase-text p {
    font-size: 18px;
    text-align: center;
  }
  .submissionForm-row {
    margin-bottom: 10%;
  }
  .faq-row {
    margin: auto;
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
    width: 115%;
  }
  .faq-heading {
    text-align: center;
  }
  .faq-card-body {
    padding: 5%;
  }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 800px) and (max-device-width: 1200px) {
  /* timeline */
  .timeline-title {
    font-size: 12px;
  }
  .timeline-info {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0 0 0.2em 0;
  }

  iframe {
    width: 100%;
  }
  /* right */
  .contactUs-text {
    font-size: 17px;
    line-height: 0.6;
  }

  .faq {
    margin-bottom: 30px;
  }
  .faq .faq-card .faq-card-header .faq-title {
    line-height: 16px;
    margin-top: 10px;
    font-size: 12px;
  }
  .faq .faq-card .faq-card-header .faq-title .badge {
    width: 15px;
    height: 15px;
    font-size: 5px;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1800px) {
  iframe {
    width: 100%;
  }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
  iframe {
    width: 100%;
  }
  .dashboard-timeline-title {
    font-size: 23px;
  }
  .dashboard-timeline-info {
    font-size: 20px;
  }
  .dashboard-timeline {
    font-size: 23px;
  }
  .currentphase-text p {
    font-size: 23px;
    text-align: center;
  }
  .dashboard-phase-btn {
    font-size: 20px;
    padding: 4% 3% 4% 3%;
  }

  .contactUs-text {
    font-size: 20px;
  }
  .contactUs-row {
    height: 8%;
  }
  .faq .faq-card .faq-card-header .faq-title {
    font-size: 21px;
  }
  .faq .faq-card .faq-card-body p {
    color: #3d4351;
    font-size: 18px;
  }
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: white;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
:root {
  --secondary: #471786;

  --gradient: linear-gradient(to right, #8e27d7, #2e1472);
  /* --main:#3C0C97; */
}
a {
  background-color: transparent;
  color: inherit;
  text-decoration: underline;
}

a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}

.heading {
  color: #36333a;
  font-size: 40px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}

.heading span {
  /* font-size: 40px; */
  background: -webkit-linear-gradient(#9538d8, #352e92);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  color: #39343d;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 1.6;
}

.blob-btn {
  z-index: 1;
  margin-right: 3%;
  position: relative;
  color: #211444;

  outline: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 50px;
  border: 2px solid #211444;
  font-size: 17px;
  overflow: hidden;
  width: 7rem;
  height: 1rem;
  min-height: 50px;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.blob-btn-text {
  outline: 0;
  text-decoration: none;
}
.blob-btn-text:hover {
  color: white;
}

.blob-btn:hover {
  color: #ffffff;
  border-radius: 50px;
  border: 2px solid transparent;
}
.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #ffffff;
}
.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  -webkit-filter: url("#goo");
          filter: url("#goo");
}
.blob-btn__blob {
  position: absolute;
  top: 3px;
  width: 25%;
  height: 100%;
  background: linear-gradient(#8e27d7, #2e1472);
  border-radius: 100%;
  -webkit-transform: translate3d(0, 150%, 0) scale(1.7);
          transform: translate3d(0, 150%, 0) scale(1.7);
  transition: -webkit-transform 0.45s;
  transition: transform 0.45s;
  transition: transform 0.45s, -webkit-transform 0.45s;
}
@supports ((-webkit-filter: url("#goo")) or (filter: url("#goo"))) {
  .blob-btn__blob {
    -webkit-transform: translate3d(0, 150%, 0) scale(1.4);
            transform: translate3d(0, 150%, 0) scale(1.4);
  }
}
.blob-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}
.blob-btn__blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}
.blob-btn__blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}
.blob-btn__blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.24s;
}
.blob-btn:hover .blob-btn__blob {
  -webkit-transform: translateZ(0) scale(1.7);
          transform: translateZ(0) scale(1.7);
}
@supports ((-webkit-filter: url("#goo")) or (filter: url("#goo"))) {
  .blob-btn:hover .blob-btn__blob {
    -webkit-transform: translateZ(0) scale(1.4);
            transform: translateZ(0) scale(1.4);
  }
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
  html,
  body {
    overflow-x: hidden;
  }
  .heading {
    font-size: 32px;
  }

  .content {
    font-size: 15px;
  }
}
@media (min-width: 1900px) and (max-width: 3000px) {
  .menu-wrap {
    position: fixed;
    top: 4%;
    left: 4%;
    z-index: 18;
  }
  .menu-wrap .toggler {
    width: 80px;
    height: 80px;
  }
  .menu-wrap .hamburger {
    width: 80px;
    height: 80px;
  }
  .menu-wrap .menu > div > div > ul > li {
    font-size: 3rem;
    padding: 5%;
  }
  .heading,
  .heading span {
    font-size: 50px;
  }
  .button-shadow {
    font-size: 25px;
  }
  .abt-btn {
    width: 150px;
  }
  .about-logo {
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
  }
  .content,
  .event-format-content {
    font-size: 23px;
  }
  .event-title {
    font-size: 35px;
    font-weight: 500;
  }

  .phase-title {
    font-size: 20px;
  }
}

/*------------------ Navbar Section ------------------------------*/

.menu-wrap {
  position: fixed;
  top: 2%;
  left: 2%;
  z-index: 18;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  z-index: 10;
  width: 60px;
  height: 60px;
  padding: 1rem;
  background: #1a1a1a;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
}

/* Hamburger Line */
.menu-wrap .hamburger > div {
  position: relative;
  -webkit-flex: none;
          flex: none;
  width: 100%;
  height: 2px;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: "";
  position: absolute;
  z-index: 20;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger > div::after {
  top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked + .hamburger > div {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Show Menu */
.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition-duration: 1s;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.menu-wrap .menu > div {
  background: rgba(0, 0, 0, 0.96);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: none;
          flex: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  font-size: 1.7rem;
  padding: 3.2%;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: #babdc0;
  font-family: "Raleway", sans-serif;
  text-decoration: none;
  /* font-weight: 500; */
  transition: color 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li > a:hover,
.menu-wrap .menu > div > div > ul > li > a:focus {
  color: #ffffff;
}

.menu-wrap .menu > div > div > ul > li > a:hover:after {
  content: "";
  display: block;
  width: 50px;
  border-bottom: 2px solid #ffffff;
  margin: -2px auto;
  border-radius: 100px;
}

/*-------------------------------------- Landing Page  -------------------------------------- */

/*play button */
.btn-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 70%;
  z-index: 10;
  width: 100px; /* Need a specific value to work */
}
.bg,
.buttons {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 100%;
  transition: all 0.6s ease-out;
}

.bg {
  -webkit-animation: pulse 1.2s ease infinite;
          animation: pulse 1.2s ease infinite;
  background: rgb(73, 74, 163);
}

.main-play-btn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-image: linear-gradient(to right, #a567d1, #560b8b);
  box-shadow: 0 0 0 2px #1a1a1a;
}

.buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  border: none;
  background: linear-gradient(to right, #8834c4, #2e1472);
  cursor: pointer;
}

.buttons i {
  position: absolute;
  color: #fff;
  font-size: 30px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}

/* mute button */

.btn-mute {
  position: absolute;
  top: 4%;
  cursor: pointer;
  right: 3%;
  background-color: #1a1a1a;
  border: none;
  outline: none;
}
.btn-mute:focus {
  outline: none;
}
.mutess i {
  cursor: pointer;
  font-size: 40px;
  color: white;
}

/* main div */

.animation-container {
  background-color: #1a1a1a;
  min-height: 60vh;
}
.wave-pattern {
  position: relative;
  bottom: 0;
  top: -4px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  z-index: 20;
}

.wave-pattern svg {
  position: relative;
  display: block;
  width: calc(153% + 1.3px);
  height: 150px;
}

.wave-pattern .shape-fill {
  fill: #1a1a1a;
}

video {
  display: block;
  width: 100%;
  height: 100%;
}
/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .wave-pattern svg {
    width: calc(160% + 1.3px);
    height: 150px;
  }
}

/** For mobile devices **/
@media (max-width: 767px) {
  .wave-pattern svg {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
    width: calc(174% + 1.3px);
    height: 100px;
  }
}

/*-------------------------------------- About Section --------------------------------------*/
.about {
  padding-left: 6%;
  padding-right: 6%;
}

.about-logo {
  width: 85%;
  margin-top: 30%;
}

/*-------------------------------------- Event format --------------------------------------*/
.event-format-row {
  margin-top: 5%;
}
.logo-small-screen {
  display: none;
}

.event-format {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  /* justify-content: space-between; */
}
.event-format-heading {
  margin-top: -8%;
  padding-bottom: 1%;
}

.phase {
  margin: 1%;
  background: #fff;

  border-radius: 13px;
  padding: 3%;
  color: #1a1a1a;
  box-shadow: rgba(143, 129, 119, 0.2) 3px 3px 10px;
  transition: 2s ease-in-out;
}

.phase:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  box-shadow: rgba(94, 66, 172, 0.2) 0px 7px 10px 3px;
}

.event-format-img {
  margin-bottom: 5%;
  display: block;
  margin: 0 auto;
}
.phase-title {
  text-align: center;
}
.phase-number {
  color: #271061;
}

.event-format-content {
  color: #2c2c2c;
  font-size: 15px;
  padding-top: 5%;
}
/*--------------------------------- Problem Statement ---------------------------------*/
.problem-stmt-row {
  margin: 5%;
}
.card-container {
  position: relative;
  -webkit-perspective: 200rem;
          perspective: 200rem;
  height: 330px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.card {
  position: absolute;
  transition: all 1.5s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: rgba(143, 129, 119, 0.2) 5px 10px 20px;
}
.card-front {
  background-color: #1a1a1a;
  height: 80%;
  width: 63%;
}

.card-back {
  padding: 5%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  height: 80%;
  width: 63%;
  box-shadow: rgba(40, 17, 105, 0.2) 0px 4px 14px 0px;
}
.sdg-number {
  text-align: center;
}
.view-sample-stmt-btn {
  font-size: 14px;
  color: #30105a;
  border: 2px solid #30105a;
  background-color: white;
  outline: none;
  transition: color 0.5s;
  border-radius: 15px;
  padding: 3% 6% 3% 6%;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.view-sample-stmt-btn:hover {
  background-image: var(--gradient);
  color: white;
  border: 2px solid #fff;
}
.card-container:hover .card-front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  /* border-radius: 100%; */
}

.card-container:hover .card-back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  /* border-radius: 100%; */
}

.card-back .card-body {
  position: relative;
}

/* -------------------------------------- Rewards -------------------------------------- */
.rewards-row {
  padding: 5%;
}
.rewards-img {
  width: 90%;
  margin-top: 20%;
  margin-left: 10%;
}
.rewards-list {
  padding-left: 7%;
  padding-right: 7%;
}
.rewards-list > li {
  margin: 0 0 10px 0;
}

/* ---- PEHIA -----*/
/* Mainsite section */
.pehia-content {
  margin-right: 8%;
  margin-left: 8%;
}
.pehia-btn {
  margin-left: 9%;
  width: 9rem;
}

/* Separate component */
.pehia-page-navbarlogo {
  width: 30%;
  height: 30%;
  margin-top: 0 !important;
}
.pehia-about {
  margin-top: 3%;
}
.fellowship {
  margin-top: 5%;
  margin-right: 6%;
  margin-left: 6%;
}
/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/

/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
@media (min-device-width: 20px) and (max-device-width: 500px) {
  html,
  body {
    overflow-x: hidden;
  }
  .heading,
  .heading span {
    font-size: 32px;
  }

  .content {
    font-size: 15px;
  }
  .menu-wrap .menu > div {
    width: 220vw;
    height: 220vw;
  }
  .menu-wrap .menu > div > div > ul > li {
    font-size: 1.5rem;
    padding: 2%;
  }

  /*------------ Ibeto main page ------------*/
  /* landing page */
  .animation-container {
    width: 105vw;
    height: 63vh;
    position: absolute;
    background-color: #1a1a1a;
  }
  .actual-line-animation {
    background-color: #1a1a1a;
    height: 63vh;
    width: 100vw;
    position: absolute;
  }
  .landingpage-img {
    height: 63vh;
    width: 100vw;
  }
  .bg,
  .buttons {
    margin-top: -190%;
    margin-left: 30%;
    width: 55px;
    height: 55px;
  }
  video {
    display: block;
    width: 100%;
    height: 100%;
  }
  .wave-pattern {
    position: relative;
    top: 5%;
    visibility: hidden;
  }
  .buttons i {
    font-size: 20px;
  }

  /* About */
  .about {
    -webkit-transform: translateY(26%);
            transform: translateY(26%);
  }

  .about-logo {
    width: 65%;
  }

  /* Event Format */

  .event-format-row {
    margin-top: 80%;
  }
  .phase {
    margin: 5%;
    margin-left: 12%;
    margin-bottom: 5%;
    margin-right: 12%;
    padding: 5%;
  }
  .phase-title {
    text-align: center;
  }
  /* Problem stmt */

  .card-front {
    height: 83%;
    width: 80%;
  }
  .card-title {
    font-size: 21px;
  }
  .card-back {
    padding: 5%;
    height: 83%;
    width: 80%;
  }
  .view-sample-stmt-btn {
    width: 90%;
    display: block;
    margin: 0 auto;
    font-size: 12px;
    text-decoration: underline;
    -webkit-text-decoration-color: white;
            text-decoration-color: white;
  }
  /* Rewards */
  .rewards-row {
    display: block;
    margin: 0 auto;
    padding: 5%;
  }
  .reward-heading {
    text-align: center;
  }
  .rewards-img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }
  .mainsite-timeline-row {
    padding-top: 15%;
  }

  /* Junior */
  .junior {
    padding: 0;
  }
  .Jcard {
    width: 100vw;
    height: 60vh;
  }
  .Jheading {
    font-size: 32px;
  }

  .Jbutton {
    right: 1px;
    width: 35px;
    height: 35px;
  }

  /* Testimonials */
  .testimonial-row {
    padding: 0%;
  }

  .testimonial-content {
    padding-right: 0%;
    padding-left: 0%;
    font-size: 13px !important;
  }
  .carousel-indicators {
    display: none;
  }
  .carousel-control {
    margin-top: 18%;
  }

  /* Sponsers */

  #Sponsors .client-logo {
    padding: 30px;
  }
  .client-logo {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  /* FAQ */
  .accordion {
    padding-left: 10%;
    padding-right: 10%;
  }
  .accordion button .icon {
    width: 3px;
    height: 3px;
  }
  .faq-heading {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .accordion button .accordion-title {
    padding: 0.4em 0.4em 0.6em 0;
  }
  .accordion-title,
  .collapse {
    font-size: 15px;
  }
  .accordion-title {
    width: 60%;
  }
  .pehia-heading {
    text-align: center;
  }
}

/** --------------------------------------------------- For tablet devices ---------------------------------------------------**/
@media (min-device-width: 501px) and (max-device-width: 800px) {
  /*Landing page wave*/
  .wave-shape-divider svg {
    width: calc(147% + 1.3px);
    height: 180px;
  }
  .card-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .phase {
    margin: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
  }
  /* timeline */
  .mainsite-timeline-row {
    padding-top: 15%;
  }
  .mainsite-timeline-title {
    font-size: 16px;
  }
  /* junior section */
  .Jheading {
    left: 45px;
    font-size: 50px;
    right: 70px;
    bottom: 7px;
  }
  .Jbutton {
    right: 15px;
    width: 40px;
    height: 40px;
  }
}

/**--------------------------------------------------- For desktop devices ----------------------------------------------------- **/

@media (min-device-width: 800px) and (max-device-width: 1800px) {
  body {
    overflow-x: hidden;
  }
  /* Event format */
  .event-format {
    margin-left: 5%;
    margin-right: 5%;
  }
  .Jheading {
    font-size: 65px;
  }
  .Jbutton {
    width: 50px;
    height: 50px;
  }
}

/**--------------------------------------------------- For Larger screen devices ----------------------------------------------------- **/
@media (min-width: 1900px) and (max-width: 3000px) {
  body {
    overflow-x: hidden;
  }
  .heading,
  .heading span {
    font-size: 60px;
  }

  .content {
    font-size: 25px;
  }
  .bg,
  .buttons {
    margin-top: -320%;
    margin-left: 3%;
    width: 140px;
    height: 140px;
  }
  .buttons i {
    font-size: 30px;
  }

  /* IBETO MAIN SITE */
  .wave-shape-divider svg {
    width: calc(130% + 1.3px);
    height: 181px;
  }

  .about-logo {
    width: 85%;
  }
  /* event */
  .event-format-img {
    width: 90%;
  }
  /* Problem stmt */
  .prbm-stmt {
    margin-bottom: 8%;
  }
  .card-front {
    height: 110%;
    width: 75%;
  }
  .card-title {
    font-size: 30px;
  }
  .card-back {
    font-size: 23px;
    padding: 5%;
    height: 110%;
    width: 75%;
  }
  .view-sample-stmt-btn {
    font-size: 20px;
  }

  .rewards-img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}

/* ------------------------------------ Footer ------------------------------------ */
.footer {
  background: #0d1117;
  width: 100.5vw;
}
.footer-row {
  /* Footer Container */

  padding: 10px 2% 10px 2%;
  text-align: center;
}
.footerMec {
  /* MEC icon */
  float: left;
}
.footerMec img {
  width: 150px;
}
.footerExcel {
  /* Excel icon*/
  float: right;
}
.footerExcel img {
  width: 150px;
}

.footerIcon {
  /* Social icons */
  display: inline-block;
  margin: 0 auto;
}

.footerIcon2 {
  display: none;
}

.social-icons {
  padding: 0;
  margin: 0;
  text-align: center;
}
.social-icons li {
  color: #fff;
  vertical-align: top;
  display: inline;
}
.social-icons a {
  color: #fff;
  text-decoration: none;
}
.fa-facebook,
.fa-instagram,
.fa-linkedin-footer {
  font-size: 25px;
  padding: 10px 14px;
  transition: 0.5s;
}
.fa-facebook:hover {
  background-color: #3d5b99;
}
.fa-linkedin-footer:hover {
  background-color: #0073a4;
}
.fa-instagram:hover {
  background-color: #e64a41;
}
.footer-text {
  color: white;
}
@media only screen and (max-width: 550px) {
  /* Footer */
  .footerMec img {
    width: 100px;
  }

  .footerExcel img {
    width: 60px;
    height: 60px;
  }
  .footerIcon {
    display: none;
  }
  .footerIcon2 {
    margin-top: 50px;
    display: block;
    margin-left: 26%;
    margin-right: 20%;
    padding-bottom: 20px;
  }
}

.carousel-control {
  left: -2px;
}
.carousel-control.right {
  right: -2px;
}
.whyparticipate-maindiv {
  margin-left: 10%;
}
.carousel-inner {
  height: 100%;
}
.whyparticipate-div {
  margin: 1%;
  margin-top: 1%;
  margin-bottom: 5%;
  padding-top: 4%;
  text-align: center;
  border-radius: 6px;
  border-bottom: 1px solid var(--secondary);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px -4px 8px rgba(255, 255, 255, 0.8);
}
.whyparticipate-div:hover {
  transition: all 0.8s;
}
.whyparticipate-img {
  display: block;
  width: 38%;
  margin-left: auto;
  margin-right: auto;
}
.whyparticipate-img-smallscreen {
  width: 60%;
}
.carousel-control-prev,
.carousel-control-next {
  color: #424141;
  font-size: 4em;
  text-decoration: none;
}
.carousel-control-prev:link,
.carousel-control-next:link {
  color: #494848;
  font-size: 4em;
  text-decoration: none;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: var(--secondary);
}

.whyparticipate-smallscreen-div {
  text-align: center;
  padding: 15%;
  width: 75%;
  margin-left: 13%;
  margin-right: 13%;
  padding: auto;
  border-radius: 6px;
  border-bottom: 1px solid var(--secondary);
  height: 280px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px -4px 8px rgba(255, 255, 255, 0.8);
  margin-bottom: 20%;
}

.whyparticipate-img-smallscreen {
  margin-top: 5%;
  margin-bottom: 3%;
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .whyparticipate-bigscreen {
    padding-right: 10%;
  }
  .whyparticipate-div {
    margin: 1%;
    padding-left: 3%;
    margin-left: 5%;
    padding-right: 3%;
    margin-top: 1%;
    margin-bottom: 5%;
    padding-top: 5%;
  }
  .carousel-control-next {
    -webkit-transform: translateX(90%);
            transform: translateX(90%);
  }

  .whyparticipate-img {
    width: 50%;
  }
  .carousel-control.right {
    margin-left: 20%;
  }
}

#contact {
  margin: 0 10% 0 25%;
}
.profile-container {
  padding: 4% 7% 4% 7%;
}
.profile-card {
  padding: 40px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border: #fff solid 4px;
  transition: 0.2s;
  box-shadow: rgba(77, 82, 88, 0.1) 0px 2px 2px 2px;
}
.profile-card:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
  box-shadow: rgba(41, 41, 43, 0.2) 0px 7px 29px 10px;
  z-index: 10;
  border: none;
}

.profile-icon {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.only-start {
  background: linear-gradient(
    to bottom,
    rgb(61, 58, 71),
    rgb(37, 35, 39) 100px,
    white 1rem,
    white 100%
  );
}

.profile-name {
  font-size: 21px;
  font-weight: bold;
  margin: 10px 0 1px 0;
}

.profile-position {
  font-size: 15px;
  color: rgb(53, 52, 52);
}
.profile-contact {
  font-size: 12px;
}

@media screen and (max-width: 991px) {
  .profile-name {
    font-size: 18px;
  }

  .profile-container {
    padding: 4% 15% 4% 15%;
  }
}

@media screen and (max-width: 768px) {
  .profile-card {
    padding: 25px 15px;
  }

  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(34, 33, 33),
      rgb(34, 33, 33) 80px,
      white 1rem,
      white 100%
    );
  }

  .profile-card {
    padding: 20px 0px;
  }

  .profile-name {
    font-size: 16px;
  }

  .profile-container {
    padding: 4% 25% 4% 25%;
  }
}

@media (min-device-width: 20px) and (max-device-width: 500px) {
  .profile-card {
    width: 50vw;
    padding: 5px;
    background-color: "blue";
  }
  .profile-icon {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 50%;
  }
  .profile-position {
    text-align: center;
    width: 70%;
  }

  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(34, 33, 33),
      rgb(34, 33, 33) 60px,
      white 1rem,
      white 100%
    );
  }
}

/* --------------------- Social Icons --------------------- */
.social-icons {
  display: -webkit-flex;
  display: flex;
  margin-top: 15%;
}

.social-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 1rem 0.6rem;
  border-radius: 50%;
  font-size: 2.5rem;
  text-decoration: none;
  transition: all 0.15s ease;
}

.social-icon:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.social-icon--linkedin,
.social-icon--envelope,
.social-icon--phone {
  background: #1a1a1a;
}
.social-icon i {
  position: relative;
  font-size: 16px;
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .profile-icon {
    height: 150px;
    width: 150px;
  }
  .only-start {
    background: linear-gradient(
      to bottom,
      rgb(61, 58, 71),
      rgb(37, 35, 39) 140px,
      white 1rem,
      white 100%
    );
  }
  .profile-name {
    font-size: 28px;
    font-weight: bold;
    margin: 10px 0 1px 0;
  }

  .profile-position {
    font-size: 20px;
    color: rgb(53, 52, 52);
  }
  .profile-contact {
    font-size: 18px;
  }
}

.junior {
  padding: 4%;
  background-color: #1a1a1a;
  position: relative;
}
.Jcard {
  margin-top: 6%;
  margin-bottom: 1%;
  position: relative;
  background-color: #1a1a1a;
  overflow: hidden;
}
.Jheading {
  z-index: 5;
  font-family: "Ralesway", sans-serif;
  position: absolute;
  bottom: 0;
  right: 95px;
  font-weight: 800;
  color: #faf4f4;
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}
.coming-soon {
  font-family: "Ralesway", sans-serif;
  position: absolute;
  bottom: 0;
  right: 95px;
  font-weight: 700;
  color: #c5c5c5;
}

.Jarrow {
  opacity: 1;
  color: #fff;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: all 0.2s ease;
  z-index: 100;
  text-align: center;
  display: block;
  height: 90%;
  width: 90%;
  margin: auto;
  margin-top: -50%;
  font-size: 100px;
  font-weight: 900;
  cursor: pointer;
}

.Jbutton {
  position: absolute;
  right: 25px;
  bottom: 18px;
  background-color: #a87ed8;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  mix-blend-mode: hard-light;
  visibility: hidden;
}
.Jarrow {
  padding-top: 7%;
}

.junior-hover-section:hover .Jarrow,
.Jbutton:hover {
  opacity: 1;
}
.junior-hover-section:hover button,
.Jbutton:hover {
  -webkit-transform: scale(8);
          transform: scale(8);
}
.Jcard button:focus {
  border: none;
  outline: none;
}

.mainsite-footer-animation {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.mainsite-footer-animation svg {
  position: relative;
  display: block;
  width: calc(153% + 1.3px);
  height: 150px;
}

.mainsite-footer-animation .shape-fill {
  fill: #ffffff;
}

/** For tablet devices **/
@media (min-width: 601px) and (max-width: 1023px) {
  .mainsite-footer-animation svg {
    width: calc(160% + 1.3px);
    height: 150px;
  }
}

/** For mobile devices **/
@media (max-width: 700px) {
  .mainsite-footer-animation svg {
    width: calc(174% + 1.3px);
    height: 90px;
  }
}

@media (max-width: 500px) {
  .mainsite-footer-animation svg {
    width: calc(164% + 1.3px);
    height: 50px;
  }
  .junior-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 90%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: absolute;
    bottom: 0;
  }
  .Jheading {
    position: static;
    margin-bottom: -8px;
  }
  .coming-soon {
    position: static;
  }
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .mainsite-footer-animation svg {
    width: calc(127% + 10px);
    height: 170px;
  }
  .Jheading {
    font-size: 75px;
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
  }
  .coming-soon {
    font-size: 30px;
    right: 95px;
  }
}

:root {
  --timeline: #5a368a;
}
.hexa {
  border: 0px;
  float: left;
  text-align: center;
  height: 25px;
  width: 50px;
  font-size: 22px;
  background: #f0f0f0;
  color: #3c3c3c;
  position: relative;
  margin-top: 15px;
  z-index: 100;
}

.hexa:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 5px solid #f0f0f0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  top: -15px;
}

.hexa:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #f0f0f0;
  bottom: -15px;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: " ";
  display: block;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background: rgb(213, 213, 213);
  z-index: 0;
}

.timeline li {
  padding: 3em 0;
}

.timeline .hexa {
  width: 16px;
  height: 10px;
  position: absolute;
  background: #5a368a;
  background: var(--timeline);
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -30px;
  margin-top: 0;
}

.timeline .hexa:before {
  border-bottom: 4px solid #5a368a;
  border-bottom: 4px solid var(--timeline);
  border-left-width: 8px;
  border-right-width: 8px;
  top: -4px;
}

.timeline .hexa:after {
  border-left-width: 8px;
  border-right-width: 8px;
  border-top: 4px solid #5a368a;
  border-top: 4px solid var(--timeline);
  bottom: -4px;
}

.direction-l,
.direction-r {
  float: none;
  width: 100%;
  text-align: center;
}

.flag-wrapper {
  text-align: center;
  position: relative;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(255, 255, 255);
  font-weight: 600;
  color: #140c22;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
}

.direction-l .time-wrapper {
  float: none;
}

.direction-r .time-wrapper {
  float: none;
}

.time {
  display: block;
  position: relative;
  z-index: 14;
  color: #fff;
  background-image: linear-gradient(to right, #7819bb, #4b44ad);
  display: inline-block;
  padding: 8px;
  margin-bottom: 10px;
}

.direction-l .desc,
.direction-r .desc {
  position: relative;
  margin: 1em 1em 0 1em;
  padding: 1em;
  z-index: 15;
}

@media (min-width: 768px) {
  .timeline {
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .timeline .hexa {
    left: -28px;
    right: auto;
    top: 8px;
  }

  .timeline .direction-l .hexa {
    left: auto;
    right: -28px;
  }

  .direction-l {
    position: relative;
    width: 310px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 310px;
    float: right;
    text-align: left;
  }

  .flag-wrapper {
    display: inline-block;
  }

  .flag {
    font-size: 18px;
  }

  .direction-l .flag:after {
    left: auto;
    right: -16px;
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(254, 254, 254);
    border-width: 8px;
  }

  .direction-r .flag:after {
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(254, 254, 254);
    border-width: 8px;
    left: -8px;
  }

  .time-wrapper {
    display: inline;
    vertical-align: middle;
    margin: 0;
  }

  .direction-l .time-wrapper {
    float: left;
  }

  .direction-r .time-wrapper {
    float: right;
  }

  .time {
    padding: 5px 10px;
    font-size: 15px;
  }

  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
}

@media (min-width: 992px) {
  .timeline {
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .direction-l {
    position: relative;
    width: 380px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 380px;
    float: right;
    text-align: left;
  }
}
@media (min-width: 1900px) and (max-width: 3000px) {
  .flag {
    font-size: 25px;
    width: 100%;
    height: 100%;
  }
  .time {
    font-size: 22px;
    width: 100%;
  }

  .hexa {
    font-size: 22px;
  }
}

.testimonial-row {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 8%;
  text-align: center;
}
.carousel-control {
  background: none;
  color: #cacaca;
  font-size: 4em;
  text-decoration: none;
}
.testimonial-img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
}
.carousel-control:hover {
  color: #471786;
}

#quote-carousel .carousel-indicators {
  position: relative;
  top: auto;
  bottom: 0px;
  margin-top: 20px;
}
#quote-carousel .carousel-indicators li {
  width: 10px;
  height: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  opacity: 0.4;
  overflow: hidden;
  transition: all 0.4s ease-in;
}

.carousel-indicators {
  z-index: 0;
}

#quote-carousel .carousel-indicators .active {
  width: 15px;
  height: 15px;
  opacity: 1;
  transition: all 0.2s;
}
.ibeto-testimonial blockquote p:before {
  content: "\f10d";
  font-family: "Fontawesome";
  float: left;
  margin-right: 10px;
  color: #471786;
  font-size: larger;
}
.ibeto-testimonial blockquote p:after {
  content: "\f10e";
  font-family: "Fontawesome";
  float: right;
  margin-right: 10px;
  color: #471786;
  font-size: larger;
}

.ibeto-testimonial p {
  padding-right: 10%;
  padding-left: 10%;
  font-size: 17px;
  color: #2d2a31 !important;
}

#quote-carousel .carousel-control {
  background: none;
  color: #cacaca;
  font-size: 100em;
  text-shadow: none;
  margin-top: 30px;
}
@media (min-width: 1900px) and (max-width: 3000px) {
  .bigscreen-testimonial {
    margin-left: 20%;
  }
  .ibeto-testimonial p {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 25px;
    color: #2d2a31;
  }
  .carousel-control {
    background: none;
    color: #cacaca;
    font-size: 6em;
    text-decoration: none;
  }
  #quote-carousel .carousel-indicators li {
    width: 20px;
    height: 20px;
  }
  #quote-carousel .carousel-indicators .active {
    width: 30px;
    height: 30px;
  }
}

#Sponsors {
  padding: 60px 0;
}
#Sponsors .clients-wrap {
  margin-bottom: 30px;
  visibility: visible;
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
#Sponsors .client-logo {
  padding: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  height: 160px;
}
#Sponsors img {
  transition: all 0.4s ease-in-out;
}

@media (min-width: 1900px) and (max-width: 3000px) {
  .client-logo {
    padding: 20%;
  }
}

#FAQ {
  padding: 2%;
}
.accordion .accordion-item {
  border-bottom: 1px solid #dddbdb;
}
.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: none;
}
.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  padding-right: 10%;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: var(--secondary);
}
.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: var(--secondary);
  border: 1px solid var(--secondary);
}
.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
  color: #2d2d2d;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: var(--secondary);
}
.accordion button[aria-expanded="true"] {
  color: var(--secondary);
  font-weight: 600;
}
.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
  color: var(--secondary);
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
  color: var(--secondary);
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}
@media (min-width: 1900px) and (max-width: 3000px) {
  #FAQ {
    padding: 0%;
  }
  .accordion button {
    font-size: 25px;
  }
}

.page_404 {
  display: block;
  margin: 0 auto;
  padding-left: 10%;
  background: #fff;
  font-family: "Raleway", sans-serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(/static/media/404.f5874472.gif);
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background-image: linear-gradient(to right, #8e27d7, #2e1472);
  margin: 20px 0;
  display: inline-block;
  border-radius: 10px;
  text-decoration: none;
}
.link_404:hover {
  background-image: linear-gradient(to right, #5951cc, #712fa0);
}
.contant_box_404 {
  margin-top: -50px;
}

.loader_container {
   /* width: 100%;
  height: 100vh;
  color: #1a1a1a;
  z-index: 9999; */
   -webkit-transform: translateX(-7%);
           transform: translateX(-7%);
   top: calc(10% - 4em);
   left: 7%;
   -webkit-align-items: center;
           align-items: center;
   background-color: #1a1a1a;
   display: -webkit-flex;
   display: flex;
   height: 100%;
   -webkit-justify-content: center;
           justify-content: center;
   margin: 0;
   position: absolute;
   width: 100%;
}

.filter {
   position: absolute;
   visibility: hidden;
}

.dots {
   -webkit-filter: url(#gooeyness);
           filter: url(#gooeyness);
   padding: 30px;
}

.dot {
   background: white;
   border-radius: 50%;
   display: inline-block;
   margin-right: 20px;
   width: 32px;
   height: 32px;
}

.dot:first-child {
   -webkit-animation: FirstDot 4.5s infinite;
           animation: FirstDot 4.5s infinite;
}

.dot:nth-child(2) {
   -webkit-animation: SecondDot 4.5s infinite;
           animation: SecondDot 4.5s infinite;
}

.dot:nth-child(3) {
   -webkit-animation: ThirdDot 4.5s infinite;
           animation: ThirdDot 4.5s infinite;
}

.dot:nth-child(4) {
   -webkit-animation: FourthDot 4.5s infinite;
           animation: FourthDot 4.5s infinite;
}

.dot:nth-child(5) {
   -webkit-animation: FifthDot 4.5s infinite;
           animation: FifthDot 4.5s infinite;
}

@-webkit-keyframes FirstDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   25% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   83% {
      -webkit-transform: scale(1) translateX(240px);
              transform: scale(1) translateX(240px);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@keyframes FirstDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   25% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   83% {
      -webkit-transform: scale(1) translateX(240px);
              transform: scale(1) translateX(240px);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@-webkit-keyframes SecondDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   27% {
      -webkit-transform: translateX(-40px);
              transform: translateX(-40px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   81% {
      -webkit-transform: translateX(180px);
              transform: translateX(180px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes SecondDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   27% {
      -webkit-transform: translateX(-40px);
              transform: translateX(-40px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   81% {
      -webkit-transform: translateX(180px);
              transform: translateX(180px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes ThirdDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   29% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   79% {
      -webkit-transform: translateX(120px);
              transform: translateX(120px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes ThirdDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   29% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   79% {
      -webkit-transform: translateX(120px);
              transform: translateX(120px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes FourthDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   31% {
      -webkit-transform: translateX(-160px);
              transform: translateX(-160px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   77% {
      -webkit-transform: translateX(60px);
              transform: translateX(60px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@keyframes FourthDot {
   0% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   31% {
      -webkit-transform: translateX(-160px);
              transform: translateX(-160px);
   }
   50% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
   }
   77% {
      -webkit-transform: translateX(60px);
              transform: translateX(60px);
   }
   100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
   }
}

@-webkit-keyframes FifthDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   33% {
      -webkit-transform: scale(1) translateX(-220px);
              transform: scale(1) translateX(-220px);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   75% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

@keyframes FifthDot {
   0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   33% {
      -webkit-transform: scale(1) translateX(-220px);
              transform: scale(1) translateX(-220px);
   }
   50% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
   75% {
      -webkit-transform: scale(2.5) translateX(0);
              transform: scale(2.5) translateX(0);
   }
   100% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
   }
}

/* .loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 5px solid rgb(10, 10, 10);
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
} */

.loader--hide {
   display: none;
}

