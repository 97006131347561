/*------------------ Navbar Section ------------------------------*/

.menu-wrap {
  position: fixed;
  top: 2%;
  left: 2%;
  z-index: 18;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  z-index: 10;
  width: 60px;
  height: 60px;
  padding: 1rem;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Hamburger Line */
.menu-wrap .hamburger > div {
  position: relative;
  flex: none;
  width: 100%;
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* Hamburger Lines - Top & Bottom */
.menu-wrap .hamburger > div::before,
.menu-wrap .hamburger > div::after {
  content: "";
  position: absolute;
  z-index: 20;
  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}

/* Moves Line Down */
.menu-wrap .hamburger > div::after {
  top: 10px;
}

/* Toggler Animation */
.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}

/* Turns Lines Into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

/* Show Menu */
.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
  transform: scale(1);
  transition-duration: 1s;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease 0.4s;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-wrap .menu > div {
  background: rgba(0, 0, 0, 0.96);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  font-size: 1.7rem;
  padding: 3.2%;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: #babdc0;
  font-family: "Raleway", sans-serif;
  text-decoration: none;
  /* font-weight: 500; */
  transition: color 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li > a:hover,
.menu-wrap .menu > div > div > ul > li > a:focus {
  color: #ffffff;
}

.menu-wrap .menu > div > div > ul > li > a:hover:after {
  content: "";
  display: block;
  width: 50px;
  border-bottom: 2px solid #ffffff;
  margin: -2px auto;
  border-radius: 100px;
}

/*-------------------------------------- Landing Page  -------------------------------------- */

/*play button */
.btn-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 70%;
  z-index: 10;
  width: 100px; /* Need a specific value to work */
}
.bg,
.buttons {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 100%;
  transition: all 0.6s ease-out;
}

.bg {
  animation: pulse 1.2s ease infinite;
  background: rgb(73, 74, 163);
}

.main-play-btn:hover {
  transform: scale(1.1);
  background-image: linear-gradient(to right, #a567d1, #560b8b);
  box-shadow: 0 0 0 2px #1a1a1a;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: none;
  background: linear-gradient(to right, #8834c4, #2e1472);
  cursor: pointer;
}

.buttons i {
  position: absolute;
  color: #fff;
  font-size: 30px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* mute button */

.btn-mute {
  position: absolute;
  top: 4%;
  cursor: pointer;
  right: 3%;
  background-color: #1a1a1a;
  border: none;
  outline: none;
}
.btn-mute:focus {
  outline: none;
}
.mutess i {
  cursor: pointer;
  font-size: 40px;
  color: white;
}

/* main div */

.animation-container {
  background-color: #1a1a1a;
  min-height: 60vh;
}
.wave-pattern {
  position: relative;
  bottom: 0;
  top: -4px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(0deg);
  z-index: 20;
}

.wave-pattern svg {
  position: relative;
  display: block;
  width: calc(153% + 1.3px);
  height: 150px;
}

.wave-pattern .shape-fill {
  fill: #1a1a1a;
}

video {
  display: block;
  width: 100%;
  height: 100%;
}
/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .wave-pattern svg {
    width: calc(160% + 1.3px);
    height: 150px;
  }
}

/** For mobile devices **/
@media (max-width: 767px) {
  .wave-pattern svg {
    transform: translateY(30%);
    width: calc(174% + 1.3px);
    height: 100px;
  }
}

/*-------------------------------------- About Section --------------------------------------*/
.about {
  padding-left: 6%;
  padding-right: 6%;
}

.about-logo {
  width: 85%;
  margin-top: 30%;
}

/*-------------------------------------- Event format --------------------------------------*/
.event-format-row {
  margin-top: 5%;
}
.logo-small-screen {
  display: none;
}

.event-format {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.event-format-heading {
  margin-top: -8%;
  padding-bottom: 1%;
}

.phase {
  margin: 1%;
  background: #fff;

  border-radius: 13px;
  padding: 3%;
  color: #1a1a1a;
  box-shadow: rgba(143, 129, 119, 0.2) 3px 3px 10px;
  transition: 2s ease-in-out;
}

.phase:hover {
  transform: scale(1.5);
  box-shadow: rgba(94, 66, 172, 0.2) 0px 7px 10px 3px;
}

.event-format-img {
  margin-bottom: 5%;
  display: block;
  margin: 0 auto;
}
.phase-title {
  text-align: center;
}
.phase-number {
  color: #271061;
}

.event-format-content {
  color: #2c2c2c;
  font-size: 15px;
  padding-top: 5%;
}
/*--------------------------------- Problem Statement ---------------------------------*/
.problem-stmt-row {
  margin: 5%;
}
.card-container {
  position: relative;
  perspective: 200rem;
  height: 330px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.card {
  position: absolute;
  transition: all 1.5s;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: rgba(143, 129, 119, 0.2) 5px 10px 20px;
}
.card-front {
  background-color: #1a1a1a;
  height: 80%;
  width: 63%;
}

.card-back {
  padding: 5%;
  transform: rotateY(180deg);
  height: 80%;
  width: 63%;
  box-shadow: rgba(40, 17, 105, 0.2) 0px 4px 14px 0px;
}
.sdg-number {
  text-align: center;
}
.view-sample-stmt-btn {
  font-size: 14px;
  color: #30105a;
  border: 2px solid #30105a;
  background-color: white;
  outline: none;
  transition: color 0.5s;
  border-radius: 15px;
  padding: 3% 6% 3% 6%;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.view-sample-stmt-btn:hover {
  background-image: var(--gradient);
  color: white;
  border: 2px solid #fff;
}
.card-container:hover .card-front {
  transform: rotateY(180deg);
  /* border-radius: 100%; */
}

.card-container:hover .card-back {
  transform: rotateY(0deg);
  /* border-radius: 100%; */
}

.card-back .card-body {
  position: relative;
}

/* -------------------------------------- Rewards -------------------------------------- */
.rewards-row {
  padding: 5%;
}
.rewards-img {
  width: 90%;
  margin-top: 20%;
  margin-left: 10%;
}
.rewards-list {
  padding-left: 7%;
  padding-right: 7%;
}
.rewards-list > li {
  margin: 0 0 10px 0;
}

/* ---- PEHIA -----*/
/* Mainsite section */
.pehia-content {
  margin-right: 8%;
  margin-left: 8%;
}
.pehia-btn {
  margin-left: 9%;
  width: 9rem;
}

/* Separate component */
.pehia-page-navbarlogo {
  width: 30%;
  height: 30%;
  margin-top: 0 !important;
}
.pehia-about {
  margin-top: 3%;
}
.fellowship {
  margin-top: 5%;
  margin-right: 6%;
  margin-left: 6%;
}
/** --------------------------------------------------- For mobile devices ---------------------------------------------------**/
